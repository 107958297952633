/* css tweaking */

/* select.form-control {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%20fill%3D%22%23555555%22%20%0A%09%20width%3D%2224px%22%20height%3D%2224px%22%20viewBox%3D%22-261%20145.2%2024%2024%22%20style%3D%22enable-background%3Anew%20-261%20145.2%2024%2024%3B%22%20xml%3Aspace%3D%22preserve%22%3E%0A%3Cpath%20d%3D%22M-245.3%2C156.1l-3.6-6.5l-3.7%2C6.5%20M-252.7%2C159l3.7%2C6.5l3.6-6.5%22%2F%3E%0A%3C%2Fsvg%3E");
  padding-right: 25px;
  background-repeat: no-repeat;
  background-position: right center;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
} */

.form-control.transparent-white {
  background-color: transparent;
  color: var(--white);
}

.form-control.transparent-white > option {
  color: initial;
}

html {
  font-size: 12px;
}

@media screen and (min-width: 768px) {
  html {
    font-size: 14px;
  }
}

@media screen and (min-width: 992px) {
  html {
    font-size: 16px;
  }
}

@media screen and (min-width: 1920px) {
  html {
    font-size: 18px;
  }
}

.cursor-pointer,
.pointable {
  cursor: pointer !important;
}

.cursor-default,
.btn.disabled {
  cursor: default !important;
}

@media screen and (min-width: 768px) {
  .dropdown-menu {
    margin-top: 0;
  }
  .dropdown-toggle {
    margin-bottom: 2px;
  }
  .navbar .dropdown-toggle,
  .nav-tabs .dropdown-toggle {
    margin-bottom: 0;
  }
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: var(--light);
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: var(--light);
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: var(--gray);
}

.input-search::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--gwhite);
  opacity: 1; /* Firefox */
}

.input-search:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--gwhite);
}

.input-search::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--gwhite);
}

.badge-success-desat {
  color: var(--black) !important;
  background-color: #589f57 !important;
}

.badge-font-lg {
  font-size: 80% !important;
}
.badge-xl {
  padding: 1em 1.5em;
}

.badge-rounded-circle.badge-xl {
  height: 3em;
  padding-left: 1.4em;
  padding-right: 2.2em;
  padding-bottom: 1.9rem;
}

.btn .badge.badge-xl {
  top: -1rem;
}

.badge-float-outside.badge-xl {
  right: -1.5rem;
}

.list-group-item {
  cursor: default;
}

.bg-gray-600 {
  background-color: rgba(56, 76, 116, 0.6);
}

.bg-gray-700 {
  background-color: rgba(56, 76, 116, 0.9);
}

.bg-gradient-transparent-dark {
  background-image: linear-gradient(to bottom, transparent 0, var(--dark) 100%) !important;
}

.semi-transparent {
  opacity: 0.5;
}