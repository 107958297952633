.card-body,
.card-footer,
.card-meta,
.card-img,
.card-img-top,
.card-img-left,
.card-img-right {
  position: relative;
  min-height: 1px;
}

.card-g {
  border-radius: 0.75rem;

  .card-header:first-child {
    border-radius: calc(0.75rem - 0px) calc(0.75rem - 0px) 0 0;
  }

  .card-body:last-child {
    border-radius: 0 0 calc(0.75rem - 0px) calc(0.75rem - 0px);
  }
}
