.font-size-1 {
  @include font-size("1rem");
}
.font-size-15 {
  @include font-size("1.5rem");
}
.font-size-2 {
  @include font-size("2rem");
}
.font-size-25 {
  @include font-size("2.5rem");
}
.font-size-3 {
  @include font-size("3rem");
}
.font-size-35 {
  @include font-size("3.5rem");
}
.font-size-375 {
  @include font-size("3.75rem");
}

.line-height-125 {
  line-height: 1.25rem;
}
.line-height-15 {
  line-height: 1.5rem;
}
.line-height-2 {
  line-height: 2rem;
}
.line-height-225 {
  line-height: 2.25rem;
}
.line-height-25 {
  line-height: 2.5rem;
}
.line-height-3 {
  line-height: 3rem;
}
.line-height-325 {
  line-height: 3.25rem;
}

.font-weight-extrabold {
  font-family: "Raleway Extrabold";
}
