@mixin form-control-transparent-color($color) {
  .form-control.transparent-#{$color} {
    background-color: transparent;
    color: var(--#{$color});
    border: 1px solid var(--#{$color});
  }

  .form-control.transparent-#{$color} > option {
    color: initial;
  }

  .form-control.transparent-#{$color}.form-control-flush {
    border-top-width: 0;
    border-right-width: 0;
    border-left-width: 0;
  }
}
