//
// type.scss
// Extended from Bootstrap
//

//
// Font sizing
//

.font-size-sm {
  font-size: $font-size-sm !important;
}

.font-size-lg {
  font-size: $font-size-lg !important;

  @include media-breakpoint-down(md) {
    font-size: $font-size-lg-md !important;
  }
}


//
// Text color
//

// Gray text

@each $color, $value in $grays {
  .text-gray-#{$color} {
    color: $value !important;
  }
}

// White text opacity

.text-white-70 {
  color: fade-out($white, .3) !important;
}

.text-white-75 {
  color: fade-out($white, .25) !important;
}

.text-white-80 {
  color: fade-out($white, .2) !important;
}


// Letter spacing

.letter-spacing-lg {
  letter-spacing: $letter-spacing-lg !important;
}
