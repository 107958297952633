//
// user.scss
// Use this to write your custom SCSS
//

.badge-primary {
  color: $white;
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: $white;
}

.btn-primary {
  color: $white;
  background-color: $primary;
}

.btn-outline-primary {
  @include hover-focus {
    background-color: $primary;
    color: $white;
  }

  &.active {
    background-color: darken($primary, 5%);
    color: $white;
  }
}

@font-face {
  font-family: "Raleway";
  font-weight: 400;
  font-style: normal;
  src: url("./fonts/Raleway/static/Raleway-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Raleway";
  font-weight: 600;
  font-style: normal;
  src: url("./fonts/Raleway/static/Raleway-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Raleway";
  font-weight: 400;
  font-style: italic;
  src: url("./fonts/Raleway/static/Raleway-Italic.ttf") format("truetype");
}

@font-face {
  font-family: "Raleway";
  font-weight: 600;
  font-style: italic;
  src: url("./fonts/Raleway/static/Raleway-SemiBoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Raleway Extrabold";
  font-weight: 600;
  font-style: normal;
  src: url("./fonts/Raleway/static/Raleway-ExtraBold.ttf") format("truetype");
}

.dropdown-submenu {
  position: relative;
}

.dropdown-submenu .dropdown-menu {
  top: -1.6rem;
  left: 100%;
  margin-top: -1px;
}

.expander-button {
  position: relative;
}

.expander-button.collapsed {
  > .expander-button-icon {
    display: none;
  }
  > .expander-button-icon.collapsed {
    display: block;
  }
}

.expander-button:not(.collapsed) {
  > .expander-button-icon {
    display: block;
  }
  > .expander-button-icon.collapsed {
    display: none;
  }
}

.expander-button.collapsed {
  > .expander-button-tooltip {
    display: none;
  }
  > .expander-button-tooltip.collapsed {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
}

.expander-button:not(.collapsed) {
  > .expander-button-tooltip {
    display: none;
  }
  > .expander-button-tooltip:not(.collapsed) {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
}

.z-index1 {
  z-index: 1 !important;
}

.zindex-1 {
  z-index: 1 !important;
}

.zindex-n1 {
  z-index: -1 !important;
}

.zindex-2 {
  z-index: 2 !important;
}

.font-size-50 {
  font-size: 50% !important;
}
