// 
// user-variables.scss
// Use this to overwrite Bootstrap and Landkit variables
//

// Example of a variable override to change Landkit's background color
// Remove the "//" to comment it in and see it in action!
// $font-family-base: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", Arial, sans-serif;

$primary: #36c7f5 !default;
$primary-desat: #c8eafc !default; // NEW
$success: #8dc63f !default;
$success-desat: #589f57 !default;
$warning: #fbaf40 !default;
$warning-desat: #bc8037 !default;

$theme-colors: ("success-desat": $success-desat,
  "warning-desat": $warning-desat);

$font-family-base: 'Raleway', serif !default;