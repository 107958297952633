.lift {
  transition: box-shadow 0.25s ease, transform 0.25s ease;
}
.lift-g {
  transition: color 0.3s ease-in, background-color 0.3s ease-in, background 0.3s ease-in, box-shadow 0.3s ease-in,
    transform 0.3s ease-in !important;
}
.lift.lift-gn:hover {
  box-shadow: none !important;
}
.lift:hover,
.lift:focus {
  box-shadow: $box-shadow-lift !important;
  transform: translate3d(0, -3px, 0);
}
.lift-lg:hover,
.lift-lg:focus {
  box-shadow: $box-shadow-lift-lg !important;
  transform: translate3d(0, -5px, 0);
}
.lift-xl:hover,
.lift-xl:focus {
  box-shadow: $box-shadow-lift-lg !important;
  transform: translate3d(0, -10px, 0);
}
