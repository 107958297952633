$g-primary: #14b3ec !default;
$g-primary-desat: #bae2f3 !default;
$g-primary-desat-alt: #acd2e9 !default;
$g-primary-desat-alt2: #ecf9ff !default;
$g-primary-desat-alt3: #a9d1e720 !default;
$g-primary-dark: #1c7dab !default;
$g-primary-dark-alt: #1d8ab3 !default;
$g-primary-alt: #23c3ed !default;
$g-primary-alt2: #51c0d4 !default;
$g-primary-alt3: #398db2 !default;
$g-secondary: #435a79 !default;
$g-secondary-desat: #869ab8 !default;
$g-secondary-desat-alt: #f2f8fafa !default;
$g-secondary-desat-alt2: #7bb5c566 !default;
$g-secondary-alt: #384c74 !default;
$g-secondary-alt2: #4b5d81 !default;
$g-secondary-alt3: #435a7a !default;
$g-warning: #fb9d2c !default;
$g-warning-alt2: #f0a255 !default;
$g-warning-desat: #f4b96b !default;
$g-warning-desat-alt: #f4b96b40 !default;
$g-warning-alt: #cf8c5f !default;
$g-success: #a4ca57 !default;
$g-success-active: #56af54 !default;
$g-success-desat: #b9d792 !default;
$g-success-dark: #8ab42f !default;
$g-green-dark: #205142 !default;
$g-green-dark-desat: #205142ba !default;
$g-green-dark-alt: #216252 !default;
$g-green-dark-alt2: #216252cc !default;
$g-green-dark-alt3: #2162523d !default;
$g-black: #000000 !default;
$g-white: #ffffff !default;
$g-light: #a9d1e700 !default;
$g-light-alt: #0e3f5600 !default;
$g-dark: #1e2d4e !default;
$g-grey: #1e2d4e64 !default;
$g-grey-desat: #00000029 !default;
$g-beige: #e5e9c3 !default;

$theme-colors: () !default;

$theme-colors: (
  "gprimary": $g-primary,
  "gprimary-desat": $g-primary-desat,
  "gprimary-desat-alt": $g-primary-desat-alt,
  "gprimary-desat-alt2": $g-primary-desat-alt2,
  "gprimary-desat-alt3": $g-primary-desat-alt3,
  "gprimary-dark": $g-primary-dark,
  "gprimary-dark-alt": $g-primary-dark-alt,
  "gprimary-alt": $g-primary-alt,
  "gprimary-alt2": $g-primary-alt2,
  "gprimary-alt3": $g-primary-alt3,
  "gsecondary": $g-secondary,
  "gsecondary-desat": $g-secondary-desat,
  "gsecondary-desat-alt": $g-secondary-desat-alt,
  "gsecondary-desat-alt2": $g-secondary-desat-alt2,
  "gsecondary-alt": $g-secondary-alt,
  "gsecondary-alt2": $g-secondary-alt2,
  "gsecondary-alt3": $g-secondary-alt3,
  "gwarning": $g-warning,
  "gwarning-alt": $g-warning-alt,
  "gwarning-alt2": $g-warning-alt2,
  "gwarning-desat": $g-warning-desat,
  "gwarning-desat-alt": $g-warning-desat-alt,
  "gsuccess": $g-success,
  "gsuccess-active": $g-success-active,
  "gsuccess-desat": $g-success-desat,
  "gsuccess-dark": $g-success-dark,
  "ggreen-dark": $g-green-dark,
  "ggreen-dark-desat": $g-green-dark-desat,
  "ggreen-dark-alt": $g-green-dark-alt,
  "ggreen-dark-alt2": $g-green-dark-alt2,
  "ggreen-dark-alt3": $g-green-dark-alt3,
  "gblack": $g-black,
  "gwhite": $g-white,
  "glight": $g-light,
  "glight-alt": $g-light-alt,
  "gdark": $g-dark,
  "ggrey": $g-grey,
  "ggrey-desat": $g-grey-desat,
  "gbeige": $g-beige,
);
