@each $color, $value in $theme-colors {
  .btn-#{$color} {
    @include button-variant($value, $value);
  }
}

@each $color, $value in $theme-colors {
  .btn-outline-#{$color} {
    @include button-outline-variant($value);
  }
}

.btn-g {
  border-radius: 0.75rem;
}

.btn-pill {
  border-radius: 50rem;
}

.btn-pill.btn-g {
  padding: 1rem 2rem !important;
}

.btn-g-sm {
  font-size: 80%;
}

.btn-gsuccess {
  @include button-variant($g-success, $g-success, $g-success-active);
  color: $g-white;

  &.disabled,
  &:disabled {
    color: $g-white;
  }
}

.btn-outline-gsuccess {
  @include button-outline-variant($g-success, $g-white, $g-success-active);

  color: $g-success;

  &.disabled,
  &:disabled {
    color: $g-success;
  }
}

.btn-outline-gprimary-alt {
  @include button-outline-variant($g-primary-alt, $g-white, $g-primary-alt3);

  color: $g-primary-alt;

  &.disabled,
  &:disabled {
    color: $g-primary-alt;
  }
}

// Rounded circle

.btn-rounded-circle {
  padding-left: $btn-padding-y;
  padding-right: $btn-padding-y;
  border-radius: $rounded-pill;

  > * {
    display: block;
    line-height: 1;
    width: 1em;
  }
}

.btn-rounded-circle.btn-lg {
  padding-left: $btn-padding-y-lg;
  padding-right: $btn-padding-y-lg;
}

.btn-rounded-circle.btn-sm {
  padding-left: $btn-padding-y-sm;
  padding-right: $btn-padding-y-sm;
}
